import { z } from "zod";

import { type Shift, shiftSchema, timecardSchema } from "../types";

export enum ShiftState {
  CREATED = "CREATED",
  CLAIMED = "CLAIMED",
  CLOCKED_IN = "CLOCKED_IN",
  ON_BREAK = "ON_BREAK",
  ENDED_BREAK = "ENDED_BREAK",
  SKIPPED_BREAK = "SKIPPED_BREAK",
  CLOCKED_OUT = "CLOCKED_OUT",
  VERIFIED = "VERIFIED",
  MISSING_CLOCK_IN = "MISSING_CLOCK_IN",
  MISSING_CLOCK_OUT = "MISSING_CLOCK_OUT",
  MISSING_SIGNATURE = "MISSING_SIGNATURE",
  REJECTED = "REJECTED",
  UNVERIFIED = "UNVERIFIED",
}

export enum UserLocationState {
  INSIDE_GEOFENCE = "INSIDE_GEOFENCE",
  OUTSIDE_GEOFENCE = "OUTSIDE_GEOFENCE",
  LEFT_GEOFENCE = "LEFT_GEOFENCE",
}

export enum SolveUnpaidBreaksRolloutStage {
  OFF = "off",
  CBH_PAYS_FOR_WORKED_BREAKS = "cbh-pays-for-worked-breaks",
  FACILITIES_PAY_FOR_WORKED_BREAKS = "facilities-pay-for-worked-breaks",
}

export const solveUnpaidBreaksStageWithTargeting = z.object({
  stage: z.nativeEnum(SolveUnpaidBreaksRolloutStage),
  minimalStoreVersion: z.string(),
  minimalOtaVersion: z.string(),
});

export type SolveUnpaidBreaksRolloutStageWithTargeting = z.infer<
  typeof solveUnpaidBreaksStageWithTargeting
>;

export const shiftStateSchema = z.object({
  state: z.nativeEnum(ShiftState),
  metadata: z.object({
    userLocationState: z.nativeEnum(UserLocationState),
    isShiftOver: z.boolean(),
    hasShiftStarted: z.boolean(),
    timekeepingLimit: z.string(),
    isAfterTimekeepingLimit: z.boolean(),
    isTimekeepingLocationValidationEnabled: z.boolean(),
    isEarlyClockInEnabled: z.boolean(),
    isNfcEnabled: z.boolean(),
    displayTimeSheetSummary: z.boolean(),
    isDigitalSignatureEnabled: z.boolean(),
    isCaliforniaTimeSheetEnabled: z.boolean(),
    isUploadTimeSheetNonIpEnabled: z.boolean(),
    isSignatureSubmission: z.boolean(),
    isTimeSheetEditDisabled: z.boolean(),
    requiresLunchBreak: z.boolean(),
    isInstantPay: z.boolean(),
    solveUnpaidBreaksRolloutStage: z.nativeEnum(SolveUnpaidBreaksRolloutStage),
    solveUnpaidBreaksRolloutStageWithTargetingConfig: solveUnpaidBreaksStageWithTargeting,
    isLate: z.boolean(),
  }),
});

export type ShiftStateData = z.infer<typeof shiftStateSchema>;
export type ShiftStateMetadata = ShiftStateData["metadata"];

export enum NextShiftState {
  CLOCK_IN = "CLOCK_IN",
  BREAK_START = "BREAK_START",
  BREAK_END = "BREAK_END",
  CLOCK_OUT = "CLOCK_OUT",
  UPLOAD_TIMESHEET = "UPLOAD_TIMESHEET",
}

export type ShiftStateDependencies = Pick<
  Shift,
  | "_id"
  | "start"
  | "end"
  | "isInstantPay"
  | "verified"
  | "deleted"
  | "clockInOut"
  | "lunchInOut"
  | "timecard"
> & {
  facility: {
    tmz: string;
  };
};

export const getPresignedUrlResponseSchema = z.object({
  presigned_url: z.string(),
});
export type GetPresignedUrlResponse = z.infer<typeof getPresignedUrlResponseSchema>;

export const uploadShiftTimecardToS3RequestSchema = z.object({
  shiftId: z.string(),
  type: z.string(),
  fileBlob: z.instanceof(Blob),
});
export type UploadShiftTimecardToS3Params = z.infer<typeof uploadShiftTimecardToS3RequestSchema>;
export const uploadShiftTimecardToS3ResponseSchema = z.object({
  url: z.string(),
  filename: z.string(),
  fileStorageFileKey: z.string(),
});
export type UploadShiftTimecardToS3Response = z.infer<typeof uploadShiftTimecardToS3ResponseSchema>;

export const updateShiftTimecardRequestSchema = z.object({
  timecard: z.array(uploadShiftTimecardToS3ResponseSchema),
  shiftId: z.string(),
  location: z.tuple([z.string(), z.string()]),
});
export type UpdateShiftTimecardParams = z.infer<typeof updateShiftTimecardRequestSchema>;

export const updateShiftTimecardResponseSchema = z.object({
  success: z.boolean(),
  response: z.object({
    timecard: timecardSchema,
  }),
  error: z.string().nullable(),
});
export type UpdateShiftTimecardResponse = z.infer<typeof updateShiftTimecardResponseSchema>;

export const cancelShiftRequestSchema = z.object({
  shiftId: z.string(),
  reasonType: z.string(),
  reasonDescription: z.string(),
  isNative: z.boolean(),
});
export type CancelShiftRequest = z.infer<typeof cancelShiftRequestSchema>;

export const cancelShiftResponseSchema = z.object({
  success: z.boolean(),
  response: shiftSchema,
  error: z.string().nullable(),
});
export type CancelShiftResponse = z.infer<typeof cancelShiftResponseSchema>;
